@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.max-width {
  @apply lg:mx-auto lg:px-24 xl:px-48 px-5;
}
/* .bg-gradient {
  @apply bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600;
}
.text-gradient {
  @apply bg-clip-text bg-gradient text-transparent;
} */
.bg-gradient {
  /* Updated the gradient to a combination of colors suitable for both light and dark modes */
  @apply bg-gradient-to-r from-emerald-500 via-indigo-500 to-cyan-500;
}

.text-gradient {
  @apply bg-clip-text bg-gradient-to-r from-emerald-500 to-indigo-600 text-transparent;
}



.section {
  @apply pt-28 xl:pt-28 2xl:pt-44;
}
.text-content {
  @apply dark:text-light-content text-dark-content;
}
body {
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

*{
  font-family: "Afacad Flux", serif;
}